import styled, { keyframes } from "styled-components";

import GridColumn from "../grid/GridColumn";
import GridRow from "../grid/GridRow";
import { PlanTile, PlanTileBenefit } from "@ritual/essentials-for-react";
import intl from "../../services/intl";
import { cadencePlanDetails } from "../../utils/ritualData";
import classNames from "classnames";
import metrics from "../../utils/metrics";
import { getProductSkuForPlanId } from "../../utils/planToProduct";
import { getPropertiesForProductSku } from "../../utils/tracking/helpers";
import { Icons } from "../../utils/react-svg";
import { responsive } from "../../utils/style";

const PlanSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-1_5);

  h3.selector-header {
    margin-bottom: unset;
  }
`;

const CadenceButton = styled.button<{ selected: boolean }>`
  border: none;
  margin: var(--spacing-0_5) 0 0;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: "#f0f0f0";
  }
`;

const FadeInBorder = keyframes`
  from {
    border-color: var(--indigo-blue-20);
  }
  to {
    border-color: var(--indigo-blue);
  }
`;

const FadeOutBorder = keyframes`
  from {
    border-color: var(--indigo-blue);
  }
  to {
    border-color: var(--indigo-blue-20);
  }
`;

const PlanTileContainer = styled(PlanTile)`
  border-color: var(--indigo-blue-20);
  animation: 300ms ease-in-out reverse forwards ${FadeInBorder};

  &.one-time {
    .plan-tile__header .title {
      margin-bottom: unset;
    }
  }

  &[is-selected="false"] {
    animation: 300ms ease-in-out ${FadeOutBorder};
  }

  &[is-selected="true"] {
    animation: 300ms ease-in-out ${FadeInBorder};

    .price-row .base-price {
      font-weight: 450 !important;
    }
  }

  .plan-tile__header {
    .title {
      margin-bottom: unset;

      div.label {
        color: unset;
        margin-bottom: unset;
      }
    }
  }

  plan-tile-benefit {
    text-align: left !important;
  }

  .plan-tile-benefit-row {
    align-items: flex-end;

    ${responsive.md`
      align-items: baseline;
    `}
  }

  .benefit-icon {
    svg {
      width: 16px;
      height: 16px;

      g g {
        fill: var(--indigo-blue-60);
      }
    }
  }

  &.hide-benefits {
    .plan-tile__benefits {
      display: none;
    }
  }

  ${responsive.md`
    .title .label .typography-label1 {
      font-size: 1.125rem !important;
    }
  `}
`;

type Props = {
  cadencePlans: any[];
  selectedPlanId: string;
  setSelectedPlanId: (planId: string) => void;
};

type Plan = {
  plan_id: string;
  basePrice: number;
};

const CadenceSelector = (props: Props) => {
  const { cadencePlans, selectedPlanId, setSelectedPlanId } = props;

  if (!cadencePlans) return null;

  const handlePlanClick = (plan: Plan) => {
    const { selectorHeader } = cadencePlanDetails(plan);
    const sku = getProductSkuForPlanId(plan.plan_id);

    metrics.track("Variant Clicked", {
      ...getPropertiesForProductSku(sku),
      location: "Single Product PDP HERO",
      title: selectorHeader,
    });

    setSelectedPlanId(plan.plan_id);
  };

  const defaultPlanId =
    selectedPlanId ||
    cadencePlans.find(
      (plan: any) =>
        plan.interval_count === 30 && plan.billing_type === "recurring",
    ).plan_id;

  return (
    <PlanSelectorContainer className="test-id">
      <GridRow>
        <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
          <h3 className="typography-body2 font-circular selector-header">
            {intl.t("delivery-cadence.selector.header")}
          </h3>
          {cadencePlans.map((plan, index) => {
            const {
              selectorHeader,
              amount,
              basePrice,
              quarterlyBasePrice,
              monthlyAmount,
              monthlyPercentSavings,
              cadenceClarity,
            } = cadencePlanDetails(plan);

            const isSelected = selectedPlanId
              ? plan.plan_id === selectedPlanId
              : plan.plan_id === defaultPlanId;
            const isOneTimePlan = plan.billing_type === "one_time";

            return (
              <CadenceButton
                key={index}
                selected={isSelected}
                onClick={() => handlePlanClick(plan)}
                aria-label="cadence-selector-option"
              >
                <PlanTileContainer
                  className={!isSelected ? "hide-benefits" : ""}
                  isSelected={isSelected}
                >
                  <span
                    className={classNames("font-circular", {
                      "typography-label1": isSelected,
                      "typography-body1": !isSelected,
                    })}
                    slot="plan-tile-title"
                  >
                    {selectorHeader}
                  </span>

                  {amount && <span slot="plan-tile-final-price">{amount}</span>}

                  {!isOneTimePlan && (
                    <>
                      {basePrice && (
                        <span slot="plan-tile-base-price">
                          {quarterlyBasePrice ? quarterlyBasePrice : basePrice}
                        </span>
                      )}
                      {monthlyAmount && (
                        <span slot="plan-tile-monthly-price">{`${monthlyAmount}/month`}</span>
                      )}
                      {monthlyPercentSavings && (
                        <span slot="plan-tile-savings">{`Subscribe and Save ${monthlyPercentSavings}`}</span>
                      )}
                      <PlanTileBenefit slot="plan-tile-benefit">
                        <span
                          className="benefit-icon"
                          slot="plan-tile-benefit-icon"
                        >
                          <Icons.RecurringBenefit />
                        </span>
                        <span slot="plan-tile-benefit-text">
                          {cadenceClarity}
                        </span>
                      </PlanTileBenefit>
                      <PlanTileBenefit slot="plan-tile-benefit">
                        <span
                          className="benefit-icon"
                          slot="plan-tile-benefit-icon"
                        >
                          <Icons.TestedBenefit />
                        </span>
                        <span slot="plan-tile-benefit-text">
                          {intl.t(
                            `delivery-cadence.benefits.${
                              plan.number_of_months === 1
                                ? "monthly"
                                : "non-monthly"
                            }`,
                          )}
                        </span>
                      </PlanTileBenefit>
                    </>
                  )}
                </PlanTileContainer>
              </CadenceButton>
            );
          })}
        </GridColumn>
      </GridRow>
    </PlanSelectorContainer>
  );
};

export default CadenceSelector;
