import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useEffect, useState } from "react";
import styled from "styled-components";

// Utils
import findDosageByProduct from "../../utils/findDosageByProduct";
import { addDisclaimerLink } from "../../utils/markdown";
import { Icons } from "../../utils/react-svg";
import { Color, Opacity, rem, responsive } from "../../utils/style";

// Components
import GridColumn from "../grid/GridColumn";
import GridRow from "../grid/GridRow";
import Text from "../Text";

const CaretDownElement = Icons["CaretDown"];

const IngredientArea = styled.div`
  margin-bottom: 40px;

  ${responsive.md`
    margin-bottom: 80px;
  `}

  .r-grid-row {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-1_5, 24px);

    ${responsive.sm`
      flex-direction: row;
      gap: unset;
    `}

    [class^="r-grid-column"] > {
      flex: 1 1;
    }
  }

  &.protein-ingredient-card-right > .r-grid-row {
    display: flex;
    flex-direction: column;

    [class^="r-grid-column"] > {
      flex: 1 1;
    }

    ${responsive.sm`
      flex-direction: row-reverse;
    `}

    ${responsive.lg`
      .info-container {
        margin-right: 100px;
        margin-left: unset;
        padding-right: unset;
        padding-left: 98px;
      }
    `}
  }
`;

const InfoContainer = styled.div.attrs({
  className: "info-container",
})`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .ingredient-card-header {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-0_25, 8px);
    border-bottom: 2px solid var(--indigo-blue);

    h2 {
      margin-bottom: unset;
    }

    h2 em {
      font-family: var(--font-dutch);
      font-weight: 400;
    }

    ${responsive.sm`
      gap: var(--spacing-1_5, 24px);
      margin-bottom: var(--spacing-1_5, 24px);
    `}
  }

  ${responsive.lg`
    padding-right: 98px;
  `}

  &.details-open {
    h3 {
      border-bottom: 0;
    }

    svg {
      transform: rotate(180deg);
    }

    dl {
      margin-top: 0;
      display: flex;
    }
  }

  p {
    span {
      display: block;
      opacity: ${Opacity.light};
    }
  }

  .toggle-button-wrapper {
    // border-bottom: 1px solid var(--indigo-blue-20);
    margin-bottom: var(--spacing-1, 16px);
  }
`;

const IngredientDescriptionList = styled.dl`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--indigo-blue-20);
  padding-bottom: var(--spacing-1_5, 24px);
  margin: unset;

  ${responsive.md`
    gap: var(--spacing-1, 16px);
  `}

  ${responsive.lg`
    gap: var(--spacing-1_5, 24px);
  `}
`;

const IngredientDetailRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const DetailsToggleButton = styled.button`
  width: 100%;
  background: none;
  color: inherit;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: 500;
  line-height: ${rem(22)};
  letter-spacing: 1px;
  font-size: ${rem(16)};
  padding-top: 16px;

  ${responsive.sm`
    padding-top: unset;
  `}
`;

const IngredientTerm = styled.dt.attrs({
  className: "typography-body2 font-circular",
})`
  color: var(--indigo-blue-60);
  width: 100%;

  ${responsive.md`
    width: 40%;
    padding-right: 32px;
  `}

  ${responsive.lg`
    width: calc(1 / 3 * 100%);
  `}
`;

const IngredientDetail = styled.dd`
  color: ${Color.ritualBlue};
  width: 100%;
  font-weight: 300;

  ${responsive.md`
    width: 60%;
  `}

  ${responsive.lg`
    width: calc(2 / 3 * 100%);
  `}
`;

export default function IngredientOverviewCard({
  ingredient,
  index,
  handleClick,
  product,
}) {
  const { image } = ingredient;
  const hasWindow = typeof window !== "undefined";
  const [isMobile, setIsMobile] = useState(
    hasWindow && window.innerWidth < 1024,
  );
  const handleResize = () => {
    const windowSize = window.innerWidth;
    setIsMobile(windowSize < 750);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [areDetailsOpen, setAreDetailsOpen] = useState(false);
  const ingredientDetailsId = `ingredients-details-${index}`;
  const ingredientDetailsToggleId = `ingredients-details-toggle-${index}`;
  const dosage = findDosageByProduct(ingredient, product);

  return (
    <IngredientArea
      className={index % 2 === 0 ? "" : "protein-ingredient-card-right"}
      onClick={() => handleClick(ingredient)}
    >
      <GridRow addClass="grid-row-ingredients">
        <GridColumn xs={{ size: 6 }} s={{ size: 6 }} m={{ size: 6 }}>
          <GatsbyImage
            image={getImage(image)}
            alt={ingredient.cardImage.title || "essential ingredient"}
            style={{
              userSelect: "none",
              userDrag: "none",
              pointerEvents: "none",
              touchCallout: "none",
              width: "100%",
              backgroundColor: Color.ritualGrey,
              objectFit: "fill",
            }}
          />
        </GridColumn>

        <GridColumn xs={{ size: 6 }} s={{ size: 6 }} m={{ size: 6 }}>
          <InfoContainer className={areDetailsOpen ? "details-open" : ""}>
            <div className="ingredient-card-header">
              <h2 className="typography-headline2">
                {ingredient.name} {dosage && <em>{dosage}</em>}
              </h2>

              <p
                className="typography-body1 font-dutch"
                dangerouslySetInnerHTML={{
                  __html: addDisclaimerLink(ingredient.description),
                }}
              />
            </div>

            {isMobile && (
              <div className="toggle-button-wrapper">
                <DetailsToggleButton
                  aria-expanded={areDetailsOpen}
                  aria-controls={ingredientDetailsId}
                  id={ingredientDetailsToggleId}
                  onClick={() => {
                    setAreDetailsOpen(!areDetailsOpen);
                  }}
                >
                  <Text
                    id="product.protein.ingredient-card.details"
                    defaultMessage="Details"
                  />
                  <CaretDownElement />
                </DetailsToggleButton>
              </div>
            )}

            {(!isMobile || (isMobile && areDetailsOpen)) && (
              <IngredientDescriptionList
                id={ingredientDetailsId}
                aria-labelledby={ingredientDetailsToggleId}
              >
                {ingredient.officialName && (
                  <IngredientDetailRow>
                    <IngredientTerm>
                      <Text
                        id="product.protein.ingredient-card.name"
                        defaultMessage="Name"
                      />
                    </IngredientTerm>
                    <IngredientDetail>
                      {ingredient.officialName}
                    </IngredientDetail>
                  </IngredientDetailRow>
                )}
                {ingredient.source && (
                  <IngredientDetailRow>
                    <IngredientTerm>
                      <Text
                        id="product.protein.ingredient-card.source"
                        defaultMessage="Source"
                      />
                    </IngredientTerm>
                    <IngredientDetail>{ingredient.source}</IngredientDetail>
                  </IngredientDetailRow>
                )}
                {ingredient.supplier && (
                  <IngredientDetailRow>
                    <IngredientTerm>
                      <Text
                        id="product.protein.ingredient-card.supplier"
                        defaultMessage="Supplier"
                      />
                    </IngredientTerm>
                    <IngredientDetail>{ingredient.supplier}</IngredientDetail>
                  </IngredientDetailRow>
                )}
                {ingredient.manufacturingLocation && (
                  <IngredientDetailRow>
                    <IngredientTerm>
                      <Text
                        id="product.protein.ingredient-card.finalLocationOfManufacturing"
                        defaultMessage="Final Location of Manufacturing"
                      />
                    </IngredientTerm>
                    <IngredientDetail>
                      {ingredient.manufacturingLocation}
                    </IngredientDetail>
                  </IngredientDetailRow>
                )}
              </IngredientDescriptionList>
            )}
          </InfoContainer>
        </GridColumn>
      </GridRow>
    </IngredientArea>
  );
}
