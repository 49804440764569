import { useEffect, useState } from "react";

const useIsMobileBreakpoint = () => {
  const hasWindow = typeof window !== "undefined";
  const [isMobile, setIsMobile] = useState(
    hasWindow && window.innerWidth < 1024,
  );
  const handleResize = () => {
    const windowSize = window.innerWidth;
    setIsMobile(windowSize < 750);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return isMobile;
};

export default useIsMobileBreakpoint;
