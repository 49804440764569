import classNames from "classnames";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import useVariation from "../../hooks/useVariation";
import intl from "../../services/intl";
import planSelectors from "../../store/plan/selectors";
import productSelectors from "../../store/product/selectors";
import metrics from "../../utils/metrics";
import { Icons } from "../../utils/react-svg";
import { Color, Opacity, rem, responsive, rgba } from "../../utils/style";
import { getPropertiesForPlanId } from "../../utils/tracking/helpers";
import DiscountButtonCopy from "../bundle/DiscountButtonCopy";
import Text from "../Text";
import CtaDetailText from "./hero/CtaDetailText";
import ProductButton from "./ProductButton";

// Styled Components
const ProductCtaWrapper = styled.div`
  &.one-time {
    max-width: 100%;
    position: relative;

    button {
      width: 100%;
    }

    ${responsive.lg`
      max-width: 370px;
    `}
  }
`;

const PlanSelectorOption = styled.div`
  border: 1px solid ${rgba(Color.ritualBlue, Opacity.lighter)};
  border-radius: 6px;
  margin-bottom: 16px;
  position: relative;
  transition: all 0.2s;

  &.checked {
    border-color: ${Color.ritualBlue};
    background-color: ${rgba(Color.ritualGrey, Opacity.light)};
  }

  label {
    width: 100%;
    cursor: pointer;
    margin: 0;
    padding: 16px;
    display: flex;
    justify-content: space-between;
  }

  input {
    width: 20px;
    height: 20px;
    margin-right: 16px;
    flex: 0 0 auto;
    visibility: hidden;
  }
`;

const RadioButton = styled.div`
  position: absolute;
  top: 18px;
  left: 16px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${rgba(Color.ritualBlue, Opacity.lighter)};

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0px;
    height: 0px;
    border-radius: 50%;
    background-color: ${Color.white};
    transition: all 0.2s;
  }

  &.checked {
    background-color: ${Color.ritualBlue};

    &::after {
      width: 10px;
      height: 10px;
    }
  }
`;

const SelectorInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 100%;

  p {
    margin: 0;
    display: flex;
    justify-content: space-between;
    font-weight: 300;

    &.checked {
      font-weight: 500;
    }
  }

  > span {
    color: ${rgba(Color.ritualBlue, Opacity.light)};
    font-size: ${rem(14)};
  }
`;

const CtaDisclaimer = styled.div`
  width: 100%;
  margin-top: 16px;

  color: ${Color.lessFadedBlue};
  opacity: ${Opacity.light};
  font-size: ${rem(12)};
`;

const ValueProp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  p {
    font-size: ${rem(14)};
    font-weight: 300;
    line-height: ${rem(20)};
    text-align: left;
    margin: auto 8px;
  }
`;

const ctaValueProps = [
  { id: "shipping-cadence", copy: "1 month supply, delivered monthly" },
  { id: "free-shipping", copy: "Free shipping, always" },
  { id: "money-back", copy: "30 day money-back guarantee" },
];

const CtaValueText = () => {
  const Icon = Icons["CheckLightBlue"];

  return ctaValueProps.map((prop) => {
    return (
      <span key={prop.id}>
        <ValueProp>
          <Icon />
          <p>
            <Text
              id={`a-la-carte.subscription-props.${prop.id}`}
              defaultMessage={prop.copy}
            />
          </p>
        </ValueProp>
      </span>
    );
  });
};

const PlanSelector = ({ plans, selectedPlan, setSelectedPlan }) => {
  const messaging = {
    one_time: intl.t("a-la-carte.one-time", "One-Time Purchase"),
    recurring: intl.t("a-la-carte.recurring", "Subscribe & Save 10%"),
  };

  const planButtons = plans.map((plan) => {
    const checked = plan.id === selectedPlan.id;
    const type = plan.billingType;

    const handleKeyDown = (e) => {
      const key = e.key;
      if (key === "Enter" || key === " ") {
        setSelectedPlan(e);
      }
    };

    return (
      <PlanSelectorOption
        key={plan.id}
        className={classNames({
          checked,
        })}
      >
        <label htmlFor={plan.id}>
          <RadioButton
            tabIndex={0}
            onKeyDown={handleKeyDown}
            className={classNames({
              checked,
            })}
          />
          <input
            // Visiblity: hidden, custom radio button above
            type="radio"
            name={messaging[type]}
            id={plan.id}
            value={plan.id}
            checked={checked}
            onChange={(e) => setSelectedPlan(e)}
          />
          <SelectorInfo>
            <p
              className={classNames({
                checked,
                "mb-2": plan.billingType === "recurring",
              })}
            >
              <span>{messaging[type]}</span>
              <span>
                <DiscountButtonCopy price={plan.amount / 100} />
              </span>
            </p>
            {type === "recurring" && CtaValueText()}
          </SelectorInfo>
        </label>
      </PlanSelectorOption>
    );
  });

  return <>{planButtons}</>;
};

const ProductCta = (props) => {
  const {
    product,
    trackCTAClick,
    actionType,
    ctaDisclaimer,
    productButtonProps,
    hideSelector = false,
    sendPlanId = undefined,
  } = props;

  const allPlans = useSelector((state) =>
    planSelectors.allPlansForProductSku(state, {
      sku: product.sku,
    }),
  );

  const plansOrder = ["one_time", "recurring"];

  // sort allPlans based on billingType (recurring/one_time) and plansOrder
  const sortedPlans = allPlans.sort((a, b) => {
    const aIndex = plansOrder.indexOf(a.billingType);
    const bIndex = plansOrder.indexOf(b.billingType);
    return aIndex > bIndex ? 1 : bIndex > aIndex ? -1 : 0;
  });
  const filteredSortedPlans = sortedPlans.filter(
    (p) =>
      p.billingType === "one_time" ||
      (p.billingType === "recurring" && p.intervalCount === 30),
  );

  const [hideOneTimeOption, setHideOneTimeOption] = useState(false);
  const hideOneTimeOptionSkus = useVariation("hide-one-time-option", [
    product.sku,
  ]);
  const hasOneTimeRollout = useVariation("one-time-rollout");
  useEffect(() => {
    const hideOneTime =
      Array.isArray(hideOneTimeOptionSkus) &&
      hideOneTimeOptionSkus.includes(product.sku);

    setHideOneTimeOption(hideOneTime);
  }, [product, hideOneTimeOption, hideOneTimeOptionSkus]);

  const hasOneTimePlan = useSelector((state) => {
    let billingTypes = productSelectors.productBillingTypes(state, {
      sku: product.sku,
    });
    return billingTypes.some((billing) => billing.billingType === "one_time");
  });

  const showOneTimeOption =
    hasOneTimeRollout && hasOneTimePlan && !hideOneTimeOption;

  const defaultPlanId = hideOneTimeOption
    ? sortedPlans[0].id
    : filteredSortedPlans.find((p) => p.billingType === "recurring").id;

  const [selectedPlanId, setSelectedPlanId] = useState(defaultPlanId);

  const selectedPlan = useSelector((state) => {
    return planSelectors.planForId(state, { id: selectedPlanId });
  });

  const buttonProps = {
    actionType: actionType,
    product: product,
    trackCTAClick: trackCTAClick,
    productButtonProps: productButtonProps,
    location: "PDP Hero",
    planType: selectedPlan?.billingType || "recurring",
    showPricing: !showOneTimeOption,
    selectedPlan,
  };

  const setAndTrackSelectedPlan = (event) => {
    // event target is different with keyboard navigation
    let target = event.currentTarget.id
      ? event.currentTarget
      : event.currentTarget.nextSibling;

    metrics.track("CTA Clicked", {
      location: "hero",
      title: target.name,
      product: getPropertiesForPlanId(target.id),
    });

    setSelectedPlanId(target.id);
    sendPlanId(target.id);
  };

  return (
    <ProductCtaWrapper
      className={classNames({
        "one-time": showOneTimeOption,
      })}
    >
      {showOneTimeOption && !hideSelector && (
        <PlanSelector
          plans={hideOneTimeOption ? sortedPlans : filteredSortedPlans}
          selectedPlan={selectedPlan}
          setSelectedPlan={setAndTrackSelectedPlan}
          actionType={actionType}
        />
      )}
      <div className="w-100 d-flex flex-md-row">
        <div
          className={`w-100 ${classNames({
            "d-flex flex-column mt-2": showOneTimeOption,
          })}`}
        >
          <ProductButton {...buttonProps} />
          <CtaDetailText product={product} />
        </div>
      </div>

      {ctaDisclaimer && <CtaDisclaimer>{ctaDisclaimer}</CtaDisclaimer>}
    </ProductCtaWrapper>
  );
};

export default ProductCta;
